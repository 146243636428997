/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

@layer utilities {
    .masonry {
        column-gap: 1.5em;
        column-count: 1;
    }
    .masonry-sm {
        column-gap: 1.5em;
        column-count: 2;
    }
    .masonry-md {
        column-gap: 1.5em;
        column-count: 3;
    }
    .break-inside {
        break-inside: avoid;
    }
}
