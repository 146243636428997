/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-menu-content {
    background-color: white !important;
}

.CodeMirror-lint-tooltip {
    z-index: 300;
}
